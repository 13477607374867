// Styling for the masthead
header.masthead {
  padding-top: 10rem;
  padding-bottom: calc(10rem - 56px);
  background-image: url('../img/fleet-1600x748.jpg');
  background-position: center center;
  flex-direction: column;
  a.btn {
    margin-bottom: 10px;
  }
  @include background-cover;
  hr {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  h1 {
    font-size: 2rem;
    text-shadow: 0 0 16px rgba(0,0,0,0.85);
  }
  h2 {
    text-shadow: 0 0 10px rgba(0,0,0,0.85);
  }
  p {
    font-weight: 300;
    color: #fff;
    text-shadow: 0px 0px 6px black;
  }
  @media (min-width: 768px) {
    p {
      font-size: 1.5rem;
    }
  }
  @media (min-width: 992px) {
    height: 100vh;
    min-height: 650px;
    padding-top: 0;
    padding-bottom: 0;
    h1 {
      font-size: 3rem;
    }
    h2 {
      font-size: 2.5rem;
    }
  }
  @media (min-width: 1200px) {
    h1 {
      font-size: 5rem;
    }
    h2 {
      font-size: 3rem;
    }
  }
}
