a i.fa {
    color: #000;
}

ul {
    list-style-type: square;
}

.section-heading {
    font-size: 2.5rem;
}

#video iframe.embed-responsive-item {
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.7);
}

@media (max-width: 599px) {
    section {
        padding: 4rem 0;
    }
}