// Styling for the about section
#about {
  background-color: $graniteGray;

  @media (max-width: 991px) {
    .bob-img-wrapper {
      max-height: 380px;
      overflow: hidden;
    }
  }
}