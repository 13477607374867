// Variables

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

/* RGB */
$color1: rgba(81, 0, 0, 1);
$color2: rgba(0, 0, 0, 1);
$color3: rgba(58, 62, 59, 1);
$color4: rgba(57, 64, 83, 1);
$color5: rgba(84, 68, 43, 1);

/* RGB */
$yankeesBlue: rgba(28, 49, 68, 1);
$vermilion: rgba(218, 61, 21, 1);
$blackOlive: rgba(58, 62, 59, 1);
$graniteGray: rgba(89, 111, 98, 1);
$paleCopper: rgba(219, 127, 103, 1);

$primary: $vermilion;
$bg-dark: $yankeesBlue;

// Carousel
$carousel-control-color:            $vermilion;
$carousel-control-width:            15% !default;
$carousel-control-opacity:          .5 !default;

$carousel-indicator-width:          30px !default;
$carousel-indicator-height:         3px !default;
$carousel-indicator-spacer:         3px !default;
$carousel-indicator-active-bg:      $white !default;

$carousel-caption-width:            70% !default;
$carousel-caption-color:            $white !default;

$carousel-control-icon-width:       20px !default;

$carousel-control-prev-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23da3d15' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
$carousel-control-next-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23da3d15' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");

$carousel-transition:               transform .6s ease !default;