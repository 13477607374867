// Styling for the testimonials section
#testimonials {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    min-height: 550px;
    justify-content: center;

    @media (max-width: 500px) {
        min-height: 850px;
    }

    @media (min-width:501px) and (max-width: 800px) {
        min-height: 570px;
    }

    .carousel-indicators {
        //top: 325px;
        position: static;
        margin-top: 20px;

        li {
            background-color: #ccc;
        }

        .active {
            background-color: $vermilion;
        }
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        display: inline-block;
        width: $carousel-control-icon-width;
        height: $carousel-control-icon-width;
        background: transparent no-repeat center center;
        background-size: 100% 100%;
    }

    .carousel-control-prev-icon {
        background-image: $carousel-control-prev-icon-bg;
    }

    .carousel-control-next-icon {
        background-image: $carousel-control-next-icon-bg;
    }

@media (max-width: 991px) {
    .carousel-control-prev {
        align-items: flex-end;        
    }

    .carousel-control-next {
        align-items: flex-end
    }
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        position: relative;
        top: 8px;
    }
}

    .carousel-inner {
        overflow: visible;
    }

    .carousel-item.testimonial {
        //min-height: 20rem;

        p {
            font-size: 1.5rem;
            font-weight: 300;
            position: relative;
            z-index: 0;
            @include sans-serif-font;
            text-align: justify;

            &:first-child:before {
                content: "“";
                position: absolute;
                font-size: 12rem;
                line-height: 1em;
                color: lighten($graniteGray, 40%);
                z-index: -5;
                top: -18px;
                left: -38px;
                @include serif-font;
            }

            &.testimonial-by {
                font-size: 1rem;
                @include serif-font;
                text-align: right;
                font-style: italic;
            }
        }
    }
}


// Icons for within


